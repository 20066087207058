<template>
    <div class="d-flex all-after-header-box">
        <!-- Левое меню модуля -->
        <div 
            :class="{'drawer-left-wrap' : true, 'dlw-large' : (!drawerLeftMenuMini), 'drawer-menu-hover': drawerLeftMenuHover }"
            @mouseenter="LeftMenuMouseenter()"
            @mouseleave="LeftMenuMouseleave()"
        >
            <v-navigation-drawer
                absolute
                permanent
                class="drawer-menu"
                v-model="drawerLeftMenu"
            >
                <template v-if="$validatePermission({ permissions: ['ChancelleryIncomingDocumentsList'] })">
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        class="dm-actions-list"
                    >
                        <v-list-item-group
                            color="#009c95"
                        >
                            <v-list-item
                                link
                                class="v-item--active v-list-item--active drawer-menu-list-bigbtn"
                                @click="onCreateClicked"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-plus-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("Добавить") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-item-group>
                        <template v-for="item in items">
                            <v-list-item
                                link
                                v-if="$validatePermission(item.access)"
                                :key="item.route"
                                :to="{ name: item.route }"
                            >
                                <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>

            </v-navigation-drawer>
        </div>

        <div class="content-box">

            <router-view :key="$route.fullPath" />
            
        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex';

export default {
    name: "ChancelleryMain",
    components: {
    },
    asyncComputed: {

    },
    computed: {
        ...mapGetters(
        {
            drawerLeftMenuMini: 'isDrawerLeftMenuMinimized'
        }),
    },
    data: () => ({
        drawerLeftMenu: true,
        drawerLeftMenuHover: false,

        items: [
            {
                name: "Обработка_входящих",
                route: "ChancelleryIncomings",
                icon: "far fa-inbox-in",
                access: { permissions: ["ChancelleryIncomingDocumentsList"] }
            },
            {
                name: "Регистрация_исходящих",
                route: "ChancelleryOutgoings",
                icon: "far fa-inbox-out",
                access: { permissions: ["ChancelleryOutcomingDocumentsList"] }
            },
            {
                name: "Регистрация_внутренних",
                route: "ChancelleryInners",
                icon: "far fa-envelope",
                access: { permissions: ["ChancelleryInternalDocumentsList"] }
            },
            {
                name: "Отправка_резолюций",
                route: "ChancelleryResolutions",
                icon: "far fa-envelope",
                access: { permissions: ["ChancelleryOutcomingDocumentsList", "CanSignChancellery"] }
            }
        ],
    }),
    methods: {
        ...mapActions('global/actionsource', ['loadDataSource']),
        LeftMenuMouseenter() {
            if (this.drawerLeftMenuMini)
                this.drawerLeftMenuHover = true;
        },
        LeftMenuMouseleave() {
            this.drawerLeftMenuHover = false;  
        },
        async onCreateClicked() {
            await this.loadDataSource({ createType: "Chancellery.IncomingDocument", navigate: true, common: false });
        }
    },
    mounted() {

    },
    beforeDestroy() {

    }
}
</script>